<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">Tambah Client</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-client">Client </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Tambah
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button class="btn btn-save" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <div class="d-flex flex-row justify-content-between">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active"
                    id="pills-detail-tab"
                    data-toggle="pill"
                    href="#pills-detail"
                    role="tab"
                    aria-controls="pills-detail"
                    aria-selected="true"
                    >Detail Client
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    id="pills-contact-tab"
                    data-toggle="pill"
                    href="#pills-contact"
                    role="tab"
                    aria-controls="pills-contact"
                    aria-selected="false"
                    class="nav-link"
                    >Contact Client</a
                  >
                </li>
              </ul>
            </div>

            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-detail"
                role="tabpanel"
                aria-labelledby="pills-detail-tab"
              >
                <div class="row mt-5">
                  <div class="col-12">
                    <div class="title">Detail Client</div>
                    <div class="sub-title">
                      Tambah informasi yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row mt-2">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="nama">Nama Perusahaan : </label>
                      <input
                        type="text"
                        v-model="formData.nama"
                        @keyup="formChange('nama')"
                        :class="{ 'is-invalid': formError && formError.nama }"
                        class="form-control"
                        id="nama"
                        placeholder="Masukkan Nama Perusahaan"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.nama"
                      >
                        {{ formError.nama }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="inputAddress2">Nomor Telepon :</label>
                      <input
                        type="text"
                        v-maska="'### #########################'"
                        v-model="formData.no_telp"
                        @keyup="formChange('no_telp')"
                        :class="{
                          'is-invalid': formError && formError.no_telp,
                        }"
                        class="form-control"
                        id="inputAddress2"
                        placeholder="Masukkan Nomor Telepon"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.no_telp"
                      >
                        {{ formError.no_telp }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Nomor Handphone :</label>
                      <input
                        type="text"
                        v-maska="'#########################'"
                        v-model="formData.no_handphone"
                        @keyup="formChange('no_handphone')"
                        :class="{
                          'is-invalid': formError && formError.no_handphone,
                        }"
                        class="form-control"
                        id="inputAddress2"
                        placeholder="Masukkan  Nomor Handphone"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.no_handphone"
                      >
                        {{ formError.no_handphone }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Nomor Whatsapp :</label>
                      <input
                        type="text"
                        v-maska="'##########################'"
                        v-model="formData.no_whatsapp"
                        @keyup="formChange('no_whatsapp')"
                        :class="{
                          'is-invalid': formError && formError.no_whatsapp,
                        }"
                        class="form-control"
                        id="inputAddress2"
                        placeholder="Masukkan Nomor Whatsapp"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.no_whatsapp"
                      >
                        {{ formError.no_whatsapp }}
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label for="email">Email :</label>
                      <input
                        type="email"
                        v-model="formData.email"
                        @keyup="formChange('email')"
                        :class="{ 'is-invalid': formError && formError.email }"
                        class="form-control"
                        id="email"
                        placeholder="Masukkan Email"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.email"
                      >
                        {{ formError.email }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="inputAddress2">NPWP :</label>
                      <input
                        type="text"
                        v-maska="'##.###.###.#-###.###'"
                        v-model="formData.npwp"
                        @keyup="formChange('npwp')"
                        :class="{ 'is-invalid': formError && formError.npwp }"
                        class="form-control"
                        id="inputAddress2"
                        placeholder="Masukkan NPWP"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.npwp"
                      >
                        {{ formError.npwp }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Kategori :</label>
                      <div class="form-group edit-vendor">
                        <Select2
                          v-model="formData.id_kategori"
                          @change="formChange('id_kategori')"
                          @select="formChange('id_kategori')"
                          :class="{
                            'is-invalid': formError && formError.id_kategori,
                          }"
                          :options="OptionsKategori"
                          placeholder="Kategori"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.id_kategori"
                        >
                          {{ formError.id_kategori }}
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Departemen :</label>
                      <div class="form-group edit-vendor">
                        <Select2
                          v-model="formData.id_divisi"
                          :class="{
                            'is-invalid': formError && formError.id_divisi,
                          }"
                          :options="OptionsDepartement"
                          placeholder="Pilih Departemen"
                          :settings="{ minimumResultsForSearch: -1 }"
                          @change="formChange('id_divisi')"
                          @select="formChange('id_divisi')"
                        />
                        <div
                          class="form-error"
                          v-if="formError && formError.id_divisi"
                        >
                          {{ formError.id_divisi }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="title">Alamat Client</div>
                    <div class="sub-title">
                      Tambah alamat client yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="inputAddress2">Provinsi :</label>
                      <Select2
                        v-model="formData.id_provinsi"
                        :options="OptionsProvince"
                        placeholder="Provinsi"
                        :class="{
                          'is-invalid': formError && formError.id_provinsi,
                        }"
                        @change="locationEvent($event, 'id_provinsi')"
                        @select="locationEvent($event, 'id_provinsi')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_provinsi"
                      >
                        {{ formError.id_provinsi }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Kabupaten :</label>
                      <Select2
                        v-model="formData.id_kabupaten"
                        :options="OptionsRegion"
                        placeholder="Kabupaten"
                        :settings="{
                          settingOption: value,
                          settingOption: value,
                        }"
                        :class="{
                          'is-invalid': formError && formError.id_kabupaten,
                        }"
                        @change="locationEvent($event, 'id_kabupaten')"
                        @select="locationEvent($event, 'id_kabupaten')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_kabupaten"
                      >
                        {{ formError.id_kabupaten }}
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="inputAddress2">Kecamatan :</label>
                      <Select2
                        v-model="formData.id_kecamatan"
                        :options="OptionsDistrict"
                        placeholder="Kecamatan"
                        :settings="{
                          settingOption: value,
                          settingOption: value,
                        }"
                        :class="{
                          'is-invalid': formError && formError.id_kecamatan,
                        }"
                        @change="locationEvent($event, 'id_kecamatan')"
                        @select="locationEvent($event, 'id_kecamatan')"
                      />
                      <div
                        class="form-error"
                        v-if="formError && formError.id_kecamatan"
                      >
                        {{ formError.id_kecamatan }}
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label for="lokasi">Alamat :</label>
                      <textarea
                        type="text"
                        class="form-control"
                        id="lokasi"
                        @keyup="formChange('alamat')"
                        v-model="formData.alamat"
                        :class="{ 'is-invalid': formError && formError.alamat }"
                        placeholder="Masukkan Alamat"
                      ></textarea>
                      <div
                        class="form-error"
                        v-if="formError && formError.alamat"
                      >
                        {{ formError.alamat }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-contact"
                role="tabpanel"
                aria-labelledby="pills-contact-tab"
              >
                <div class="row mt-5">
                  <div class="col-12">
                    <div class="title">Detail Contact Client</div>
                    <div class="sub-title">
                      Tambah informasi contact client yang diperlukan
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row mt-2">
                  <div class="col-6">
                    <div class="form-group">
                      <label for="nama_contact">Nama : </label>
                      <input
                        type="text"
                        v-model="formData.contact[0].nama"
                        class="form-control"
                        id="nama_contact"
                        placeholder="Masukkan Nama Contact"
                      />
                    </div>

                    <div class="form-group">
                      <label for="no_telp_contact">Nomor Telepon :</label>
                      <input
                        type="text"
                        v-maska="'### #######'"
                        v-model="formData.contact[0].no_telp"
                        class="form-control"
                        id="no_telp_contact"
                        placeholder="Masukkan Nomor Telepon"
                      />
                    </div>
                    <div class="form-group">
                      <label for="no_handphone_contact"
                        >Nomor Handphone :</label
                      >
                      <input
                        type="text"
                        v-maska="'##############'"
                        v-model="formData.contact[0].no_handphone"
                        class="form-control"
                        id="no_handphone_contact"
                        placeholder="Masukkan Nomor Handphone"
                      />
                    </div>
                    <div class="form-group">
                      <label for="no_whatsapp_contact">Nomor Whatsapp :</label>
                      <input
                        type="text"
                        v-model="formData.contact[0].no_whatsapp"
                        v-maska="'##############'"
                        class="form-control"
                        id="no_whatsapp_contact"
                        placeholder="Masukkan Nomor Whatsapp"
                      />
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="form-group">
                      <label for="email_contact">Email :</label>
                      <input
                        type="email"
                        v-model="formData.contact[0].email"
                        class="form-control"
                        id="email_contact"
                        placeholder="Masukkan Email"
                      />
                    </div>
                    <div class="form-group">
                      <label for="jabattan">Jabatan :</label>
                      <input
                        type="text"
                        v-model="formData.contact[0].jabatan"
                        class="form-control"
                        id="jabattan"
                        placeholder="Masukkan Jabatan"
                      />
                    </div>

                    <div class="form-group">
                      <label for="inputAddress2">Status :</label>
                      <div class="form-group edit-vendor">
                        <Select2
                          v-model="formData.contact[0].status"
                          :options="statusCompany"
                          placeholder="Status"
                          :settings="{
                            settingOption: value,
                            settingOption: value,
                          }"
                          @change="myChangeEvent($event)"
                          @select="myChangeEvent($event)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import { maska } from "maska";

import $ from "jquery";
import { checkRules, cksClient, showAlert } from "../../../helper";
import {
  get_ListDivisi,
  get_ListKabupaten,
  get_ListKecamatan,
  get_ListProvinsi,
} from "../../../actions/master";
import {
  get_ListKategoriClient,
  post_SaveClient,
} from "../../../actions/client";
// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // TableLite
  },
  directives: {
    maska,
  },
  data() {
    return {
      myValue: "aaaa",
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ],
      optionPeriode: ["1 Bulan", "2 Bulan"],
      OptionsKategori: [],
      OptionsDistrict: [],
      OptionsRegion: [],
      OptionsProvince: [],
      OptionsDepartement: [],
      placeholder: [
        {
          text: "kecamatan",
        },
      ],
      id_company: cksClient().get("_account").id_company,
      isSubmit: false,
      formData: {
        id: "",
        id_kategori: "",
        id_company: cksClient().get("_account").id_company,
        nama: "",
        alamat: "",
        id_provinsi: "",
        id_kecamatan: "",
        id_kabupaten: "",
        id_divisi: "",
        status: "aktif",
        no_telp: "",
        no_handphone: "",
        no_whatsapp: "",
        email: "",
        npwp: "",
        contact: [
          {
            id: "",
            nama: "",
            jabatan: "",
            no_telp: "",
            email: "",
            no_handphone: "",
            no_whatsapp: "",
            status: "aktif",
          },
        ],
      },
      formError: [],
      rules: {
        // id_kategori: {
        //   required: false,
        // },
        nama: {
          required: true,
        },
        alamat: {
          required: true,
        },
        id_provinsi: {
          required: true,
        },
        id_kecamatan: {
          required: true,
        },
        id_kabupaten: {
          required: true,
        },
        no_handphone: {
          required: true,
        },
        email: {
          email: true,
          required: true,
        },
      },
    };
  },
  created() {
    this.getKategori();
    this.getProvinsi();
    this.getDivisi();
  },
  mounted() {
    // $("#tableDepartment").DataTable({
    //   info: false,
    // });
  },
  methods: {
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    myChangeEvent(val) {
      console.log(val);
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        var { list } = res;
        this.OptionsDepartement = list;
      });
    },
    async getKategori() {
      await get_ListKategoriClient(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          var arr = [];
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            arr.push({
              id: element.id,
              text: element.nama,
            });
          }
          this.OptionsKategori = arr;
        }
      );
    },
    async getProvinsi() {
      await get_ListProvinsi(
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsProvince = arr;
        },
        () => {
          this.OptionsProvince = [];
        }
      );
    },
    async getKabupaten(id) {
      await get_ListKabupaten(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsRegion = arr;
        },
        () => {
          this.OptionsRegion = [];
        }
      );
    },

    async getKecamatan(id) {
      await get_ListKecamatan(
        id,
        (res) => {
          var { list } = res;
          var arr = [{ id: "", value: "" }];
          for (const key in list) {
            var data = list[key];
            data.text = list[key].nama;
            arr.push(data);
          }
          this.OptionsDistrict = arr;
        },
        () => {
          this.OptionsDistrict = [];
        }
      );
    },
    locationEvent(val, key) {
      this.formChange(key);
      if (key === "id_provinsi") {
        this.OptionsDistrict = [];
        this.OptionsRegion = [];
        this.getKabupaten(val.id);
      } else if (key === "id_kabupaten") {
        this.OptionsDistrict = [];
        this.getKecamatan(val.id);
      }
    },

    postData() {
      this.isSubmit = true;
      post_SaveClient(
        this.formData,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code == 201
              ? "Data client berhasil ditambahkan"
              : "Data Client berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              if (res.id_client) {
                // localStorage.setItem("setContact", true);
                this.$router.push({
                  name: "DataClientDetail",
                  params: { id: res.id_client },
                });
              } else {
                this.$router.push({ name: "DataClient" });
              }
            },
            onDismiss: () => {
              if (res.id_client) {
                // localStorage.setItem("setContact", true);
                this.$router.push({
                  name: "DataClientDetail",
                  params: { id: res.id_client },
                });
              } else {
                this.$router.push({ name: "DataClient" });
              }
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },

    async onSubmit() {
      for (const key in this.rules) {
        if (key != "contact" && this.rules[key]) {
          if (this.rules[key]) {
            this.rules[key].changed = true;
          }
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else {
        this.isSubmit = false;
        setTimeout(() => {
          if ($("#pills-detail .form-error").length > 0) {
            $("#pills-detail-tab").tab("show");
          }
        }, 200);
      }
    },

    clickEdit() {
      $(".groupBtn-save-edit").removeClass("d-none");
      $(".groupBtn-detail").addClass("d-none");
      $("input").removeAttr("readonly");
      $("textarea").removeAttr("readonly");
      $(".edit-vendor ").removeClass("d-none");
      $(".input-none").addClass("d-none");
    },

    clickCancel() {
      $("input").attr("readonly");
      $(".groupBtn-detail").removeClass("d-none");
      $(".groupBtn-save-edit ").addClass("d-none");
      $("input").attr("readonly", true);
      $(".edit-vendor ").addClass("d-none");
      $(".input-none").removeClass("d-none");
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-save {
  background-color: #40ddd4;
  color: #fff;
  /* width: 90px; */
  font-size: 16px;
  font-weight: 400;
  padding: 10px 0px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}
</style>
